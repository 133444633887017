import React, {useEffect, useState} from 'react';
import {translate} from "react-i18nify";



const ExplainationTextField = ({active, extraTitle, onChange}) =>
    active ? <div>
            <h3>{ extraTitle }</h3>
            <textarea rows={5} cols={100} onChange={ value => onChange(value.target.value) } />
        </div>
        : <></>;

export const QuestionOneChoice = ({question, options, extraTitle, response, preAnswer, rules}) => {

    const [selected, setSelected] = useState(preAnswer);
    const [extraText, setExtraText] = useState("");

    const enableExtraTextField = rules?.explainTextFieldOn?.includes( selected );

    useEffect(() => {
        console.log( extraText )
        response( {value: selected, extra: (enableExtraTextField ? extraText : undefined)} );
    }, [selected, extraText]);

    return <fieldset className={"QuestionElement QuestionMultipleChoice"}>
        <legend className={"Question"}>{ question }</legend>
        {
            options.map( option =>
                <div key={option.id} onChange={ () => setSelected(option.id) } className={selected && selected !== option.id ? "notchoosen answers" : "answers"}>
                    <input type={"radio"} id={question + "_" + option.id} value={option.id} name={question} defaultChecked={option.id===selected} />
                    <label for={question + "_" + option.id}>
                        <div className={"icon"} selected={ selected === option.id }/>
                        <div className={"option"}>{ translate( "Sections.Questions." + option.text ) }</div>
                    </label>
                </div>)
        }
        <ExplainationTextField active={ enableExtraTextField } onChange={ setExtraText } extraTitle={ extraTitle } />
    </fieldset>
}