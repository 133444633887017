import React, {useContext, useEffect, useState} from 'react';
import {QuestionTextLine} from "./elements/questionTextLine";
import {QuestionOneChoice} from "./elements/questionOneChoice";
import {translate} from "react-i18nify";
import {Server} from "../ApplicationFrame/Server";
import {STATUS} from "../ApplicationFrame";
import {QuestionCartesian} from "./elements/questionCartesian";
import {QuestionList} from "./elements/questionAddList";
import {QuestionMultipleChoice} from "./elements/questionMultipleChoice";
import {url} from "../helpers/url";
import {Back, Next, Submit} from "../ApplicationFrame/Buttons";
import {LanguageContext} from "../ApplicationFrame/Translation";
import {QuestionLoop} from "./elements/questionLoop";
import {QuestionText} from "./elements/questionText";
import {QuestionSlider} from "./elements/questionSlider";

const TYPE = {
    "readonly": "readonly",
    "textline": "textline",
    "text": "text",
    "oneChoice": "oneChoice",
    "multipleChoice": "multipleChoice",
    "slider": "slider",
    "addList": "addList",
    "cartesian": "cartesian",
    "loop": "loop"
}

export const SURVEY = () => {

    const [QUESTIONS, setQuestions] = useState([]);
    const [status, setStatus] = useState(STATUS.unknowned);
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        switch(status){
            case STATUS.unknowned:
                Server
                    .getSurveyQuestions()
                    .then( response => setQuestions( response.data ) )
                    .catch( error => console.error( error ));
                setStatus(STATUS.loading);
                break;
            case STATUS.loading:
                if( QUESTIONS.length )
                    setStatus( STATUS.complete );
                break;
            case STATUS.complete:
            case STATUS.survey_submitted:
                break;
            default:
                setStatus( STATUS.unknowned );
        }

    }, [QUESTIONS, status]);

    if( status === STATUS.complete )
        return <div className={"SURVEY"}>
            <h1>{ translate('Sections.Questions.title') }</h1>
            <Questions catalog={QUESTIONS} isFirstLayer={true} />
        </div>
    else
        return <div></div>
}

export const Questions = ({catalog, isFirstLayer, onBack, response, context}) => {

    const [answers, setAnswers] = useState( isFirstLayer ? {meta: {}} : {} );
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentAnswerValid, setCurrentAnswerValid] = useState(false);
    const [transmitted, setTransmitted] = useState(false);

    useEffect( () => {
        if( isFirstLayer ) {
            const companyID = url.getParams().id || "";
            console.log(companyID)
            Server.getCompanyName(companyID)
                .then(response => {
                    setAnswers({
                        ...answers,
                        "nameOfCompany": response.data,
                        meta: {...answers.meta, companyID: companyID}
                    });
                });
        }
    }, [catalog]);

    useEffect( () => {
        console.log( "Ergebnis:", answers );
    }, [answers]);

    useEffect(() => {
        setAnswers({});
        setCurrentQuestion(0);
    }, [context]);


    const updateAnswer = (id, answer) => {
        setAnswers({...answers, [id]: answer} );
        if( catalog[currentQuestion].type === TYPE.loop )
            setCurrentQuestion( currentQuestion +1 );
    }

    const renderQuestionType = question => {

        switch (question.type) {
            case TYPE.readonly:
                return <>{  translate( question.text ).split("\n").map( text => <p>{text}</p>) }</>;
            case TYPE.textline:
                return <QuestionTextLine key={question.question}
                                         question={ translate("Sections.Questions." + question.question) }
                                         preAnswer={ answers[question.question] }
                                         response={text => updateAnswer(question.question, text) } />;
            case TYPE.text:
                return <QuestionText key={question.question}
                                         question={ translate("Sections.Questions." + question.question).replaceAll( "%item", context) }
                                         preAnswer={ answers[question.question] }
                                         response={text => updateAnswer(question.question, text) } />;
            case TYPE.addList:
                return <QuestionList key={question.question}
                    question={ translate("Sections.Questions." + question.question) }
                    preAnswer={ answers[question.question] }
                    rules={ question.rules }
                    response={list => updateAnswer(question.question, list) } />;
            case TYPE.slider:
                return <QuestionSlider key={question.question}
                                        question={ translate("Sections.Questions." + question.question).replaceAll( "%item", context) }
                                        preAnswer={ 0 }
                                        corner={{
                                            a: translate("Sections.Questions." + question.question + "s.a" ),
                                            b: translate("Sections.Questions." + question.question + "s.b" )}}
                                        response={text => updateAnswer(question.question, text) } />;
            case TYPE.oneChoice:
                return <QuestionOneChoice key={question.question}
                    question={ translate("Sections.Questions." + question.question).replaceAll("%item", context) }
                    extraTitle={ translate( "Sections.Questions." + question.question + "__extra_title__" ) }
                    preAnswer={ answers[question.question] }
                    options={question.options}
                    rules={ question?.rules }
                    response={value => {
                        if( answers[question.question] !== value.value )
                            updateAnswer(question.question, value.value)
                        else
                            updateAnswer(question.question+"_text", value.extra);
                    }} />;
            case TYPE.multipleChoice:
                return <QuestionMultipleChoice key={question.question}
                    question={ translate("Sections.Questions." + question.question).replaceAll("%item", context) }
                    preAnswer={ answers[question.question] }
                    options={question.options}
                    response={value => updateAnswer(question.question, value)} />;
            case TYPE.cartesian:
                return <QuestionCartesian key={question.question}
                    question={ translate("Sections.Questions." + question.question) }
                    rules={ question.rules }
                    response={position => updateAnswer(question.question, position)}/>;
            case TYPE.loop:
                return <div key={question.question}>
                    <QuestionLoop
                        catalog={ catalog[currentQuestion].loop }
                        response={ response => updateAnswer(question.question, response ) }
                        onBack={ _onBack }
                        loopOver={ answers[catalog[currentQuestion].relatedTo] }/>
                </div>;
            default:
                return <div>{ translate("global.unknownType") }</div>;
        }
    }

    useEffect( () => {
        const check = checkAnswerForComplete( catalog[currentQuestion].question );

        if( check !== currentAnswerValid )
            setCurrentAnswerValid( check );
    }, [answers, currentQuestion]);

    const checkAnswerForComplete = (id) => {
        const {type, rules} = catalog.find( entry => entry.question === id );

        switch(type){
            case TYPE.textline:
                return ( rules["min-charlength"] === -1 || answers[id]?.length >= rules["min-charlength"] )
                    && ( rules["max-charlength"] === -1 || answers[id]?.length <= rules["max-charlength"] );
            case TYPE.text:
                return ( rules["min-charlength"] === -1 || answers[id]?.length >= rules["min-charlength"] )
                    && ( rules["max-charlength"] === -1 || answers[id]?.length <= rules["max-charlength"] );
            case TYPE.addList:
                return ( rules["entries"].min === -1 ||  answers[id]?.length >= rules["entries"].min )
                    && ( rules["entries"].max === -1 ||  answers[id]?.length <= rules["entries"].max )
            case TYPE.multipleChoice:
                return ( rules["selected"].min === -1 ||  answers[id]?.length >= rules["selected"].min )
                    && ( rules["selected"].max === -1 ||  answers[id]?.length <= rules["selected"].max )
            case TYPE.oneChoice:
                return answers[id]?.length >= 0 && (
                    ! rules || answers[id+"_text"]?.length >= 0 || ! rules?.["explainTextFieldOn"]?.includes( answers[id] )
                )
            case TYPE.cartesian:
                return true;
            case TYPE.loop:
                return true;
            default:
                if( ! rules )
                    return true;
                return false;
        }
    }

    const _onBack = () => {
        if( ! isFirstLayer && currentQuestion === 0 )
            onBack();
        else
            setCurrentQuestion(currentQuestion -1);
    }
    const _onNext = () => {
        if( currentQuestion === catalog.length -1 )
            response(answers);
        else
            setCurrentQuestion(currentQuestion +1);
    }

    const _onFinalized = () => {
        setTransmitted( true );
        Server.setSurveyAnswers( answers.meta.companyID, answers );
        console.log( "alles klar... ", answers.meta.companyID, answers);
    }

    if( catalog?.length){
        return <div className={"Question"}>
            { renderQuestionType( catalog[currentQuestion] ) }
            { catalog[currentQuestion].type !== TYPE.loop &&
                <div className={"PageActions"}>
                    { isFirstLayer && currentQuestion === catalog.length -1
                        ? ( transmitted ? <></> : <Submit disabled={ ! currentAnswerValid } onClick={ _onFinalized } /> )
                        : <Next disabled={ ! currentAnswerValid } onClick={ _onNext } />
                    }
                    <Back className={"secondary"} disabled={ isFirstLayer && currentQuestion === 0 } onClick={ _onBack }/>
                </div>
            }
        </div>
    }
}